<template>
  <div class="box">
    <h2 class="xieyi">你拖我拉服务协议</h2>
    <p class="xieyiTit">
      你拖我拉是由你拖我拉共享科技（浙江）有限公司（以下简称“你拖我拉”
      或“我们”）提供
      的互联⽹软件服务（以下简称“你拖我拉”）。本服务协议（以下简称“本协议”）是您（以下简
      称“您”或“⽤⼾”）关于您访问和使⽤你拖我拉提供的服务的协议
    </p>
    <p class="xieyiTit">
      在本服务创建账⼾并使⽤之前，您必须⾸先同意本协议。在接受本协议之前，
      请您仔细阅读全部内
      容，如您⽆法准确理解或不同意本协议的任⼀内容，请不要访问、使⽤你拖我拉任
      何服务。您通过⽹络⻚⾯点击确认、
      实际购买或使⽤本服务等操作均表⽰您已阅读并充分理解本协 议之内容，具有
      达成本协议所需的⺠事权利能⼒和⺠事⾏为能⼒（或您符合本协议中“⻘少年特别
      协议”部分的要求），与你拖我拉就使⽤或订购相应产品和/或服务已达成
      合意，并同意接受本协议 的全部约定内容，受其约束。
    </p>
    <p class="xieyiTit">
      你拖我拉致⼒于打造多样的产品和服务以满⾜您的需求，我们会根据您的注册信息⽣成对应的应⽤账
      号，你拖我拉产品在向您提供服务时均可获取对应应⽤账号的信息。
    </p>
    <p class="xieyiTit">
      特别提⽰您关注本协议关于限制、免除你拖我拉责任的协议、对⽤⼾的权利限
      制协议、违规/违约⾏ 为的认定处理、以及管辖法院的选择等协议内容，前述协
      议可能以加粗及/或加下划线形式提⽰您注 意。
    </p>
    <p class="xieyiTit">
      您同意你拖我拉有权不时地对本协议进⾏修订。修订后的协议将在⽹⻚上公布的⽅式向您告知，并代
      替原来的服务协议。您继续访问和使⽤你拖我拉即视为您接受修订后的服务协议。如您不同意修订后的
      全部或者部分服务协议内容，您有权通过停⽌访问你拖我拉或者拒绝使⽤相应服务、删除您在你拖我拉上的信
      息和账⼾等⽅式以终⽌服务协议或者使修订后的内容不对您⽣效。
    </p>
    <p class="xieyiTit">
      如果您代表某个机构⽽⾮您个⼈注册、登录和使⽤你拖我拉，则您将被认为获得该机构的充分授权，有权
      代表该机构同意本服务协议以及服务协议不时的修改和补充。
    </p>
    <span class="xiaoti">1. 服务内容</span>
    <p class="xieyiTit">
      1.1
      本协议中的“你拖我拉服务”指：你拖我拉向您提供nituowola.com⽹站（即你拖我拉官⽹）上所展⽰的
      产品和服务，包括但不限于通过⽹⻚、移动端APP等⽅式提供的全部服务，具体服务内容以您所使⽤
      或订购的内容为准。
    </p>
    <p class="xieyiTit">
      1.2 若您未从你拖我拉官⽅途径获取服务的，我们⽆法保证该服务能
      够正常使⽤，我们对此种获取⽅式和使⽤⾏为造成的损失不承担任何责任。
    </p>
    <p class="xieyiTit">
      1.3
      根据实际需要和你拖我拉不时提供的其他服务内容，你拖我拉可能与您另⾏签订其他协议（“其
      他协议”）。如果其他协议和本协议之间存在冲突，应以其他协议为准，但以该冲突
      和与该协议特定事宜相关为限。
    </p>
    <p class="xieyiTit">
      1.4
      你拖我拉保留随时变更、中⽌或终⽌部分免费服务的权利。你拖我拉不承担提供的任何免费服务的调整给您造成的损
      失。你拖我拉有权在未来恰当的时机对任何免费服务内容收取相应的服务费⽤。你拖我拉做出上述调
      整前会以站内通知、邮件或官⽹公告等⽅式通知您，并给予您合理期限以完成数据的下载和备份。
    </p>
    <p class="xieyiTit">
      1.5
      您在使⽤你拖我拉服务前，应充分阅读并理解你拖我拉官⽹对相应服务的描述，尤其是对免费服务、各级
      收费服务在功能、服务标准上的限制。对由于您未能充分理解上述描述和限制⽽造成的任何损失，尽
      微致⼴不承担任何法律责任。
    </p>
    <span class="xiaoti">2. 账⼾</span>
    <p class="xieyiTit">
      2.1
      为访问与使⽤你拖我拉，您必须注册⼀个你拖我拉账⼾。注册账⼾时，您需要提供真实、准确的信息。如
      果您的信息发⽣变更，请及时在您的账⼾中对信息进⾏更新。您对（a）您的账⼾的保密性，以及⽤
      于启⽤您访问服务的任何密码、⾝份验证密钥或安全凭据的安全性负责，和（b）所有在您账⼾下的
      活动，不论是您还是您的最终⽤⼾的活动负责。如果您的账⼾下有任何未经授权或滥⽤⾏为或者任何
      与服务相关的安全事件，请您⽴即通知我们。您认可并同意我们不会对您账⼾中任何未经授权或滥⽤
      ⾏为负责，除⾮这是由我们违反本协议所造成的。
    </p>
    <p class="xieyiTit">
      2.2
      你拖我拉和您应遵守相关法律法规进⾏实名认证。就服务的使⽤，你拖我拉可能要求您提供
      进⼀步的⾝份资料、经营资质和信息，以完成⾝份认证或资格验证。仅在完成相应⾝份认证及资格验
      证后，您才可以订购及使⽤相关服务。您理解并同意你拖我拉有权⾃⾏或委托第三⽅，审查您在实名
      认证时提供的信息是否真实、准确及有效，对于违反本协议约定的账⼾信息，你拖我拉有权采取不予
      通过、屏蔽或删除等处理。您知晓并同意，如您未依照相关法律法规及你拖我拉的要求完成实名认
      证，将影响您访问或使⽤本服务。
    </p>
    <p class="xieyiTit">
      2.3
      您声明并保证，您向你拖我拉提供的信息均为真实、准确、有效、最新的，否则您应承担由此产
      ⽣的⼀切法律责任。尽管有前述规定，你拖我拉⽆义务对任何⽤⼾的任何登记资料承担任何责任，包
      括但不限于鉴别、核实任何登记资料的真实性、准确性、完整性、适⽤性及/或是否为最新资料
      的责任。
    </p>
    <p class="xieyiTit">
      2.4
      你拖我拉建议您妥善保管您的⼿机号、密码和名称等，准确输⼊该等信息将作为您访问
      登录你拖我拉并享有服务的关键环节。如果您发现有⼈未经授权使⽤您的账⼾信息或怀疑任何⼈未经授权
      可能能够访问您的私有内容，您应⽴即更改密码，并向我们反馈。
    </p>
    <p class="xieyiTit">
      2.5
      如您是企业/组织⽤⼾，您登录本软件后可以创建您的企业或组织。您可以选择通过⼿机号码、
      ⼆维码等⽅式（具体⽅式以你拖我拉实际提供的⽅式为准）邀请企业/组织成员加⼊本软件成为您的最终⽤⼾。
    </p>
    <p class="xieyiTit">
      2.6
      如果您接受邀请成为企业/组织⽤⼾的最终⽤⼾，您可以享受我们为该企业/组织⽤⼾提供的服务
      内容，服务内容以我们实际提供的功能及企业/组织⽤⼾的服务开通情况为准。您所在企业/组织及其
      管理员基于企业/组织管理需求，对您的企业/组织成员⾝份及基于该⾝份的相关权益进⾏管理，包括
      但不限于加⼊、删除以及调整权限和限制服务内容等。您保证在遵守本服务规范的同时，按照企业/
      组织的授权范围和规范使⽤本服务。
    </p>
    <p class="xieyiTit">
      2.7
      您作为企业/组织⽤⼾在上传和管理最终⽤⼾的姓名、⼿机号码等个⼈信息时，请确保您已经事
      先获得最终⽤⼾的明确同意，仅收集为运营及功能实现⽬的⽽必需的⽤⼾信息，且已充分告知最终⽤
      ⼾相关数据收集的⽬的、范围及使⽤⽅式等事项，并提⽰最终⽤⼾可通过阅读产品隐私政策进⼀步了
      解产品个⼈信息收集处理规则及其所享有的关于个⼈信息的权利。
    </p>
    <span class="xiaoti">3. 使⽤</span>
    <p class="xieyiTit">
      3.1
      您声明并保证您上传或储存在你拖我拉中的内容不违反本协议并且符合法律法规。您应对您上传或储
      存的内容的合法性、准确性、完整性和可靠性负责。您应负责获得并持续保有任何与您的内容相关
      的通知、同意或授权。除⾮法律另有明确规定，否则你拖我拉不承担任何与您上传或储存的内容有关
      的义务和责任。若您在你拖我拉上传、储存侵犯他⼈知识产权或其他违法、违规的内容，你拖我拉有权利
      予以删除并保留移交司法机关处理的权利。
    </p>
    <p class="xieyiTit">
      3.2
      您不得使⽤你拖我拉上传、储存、共享、发送或传播任何敏感信息和违反国家法律法规的信息，包括
      但不限于下列信息
    </p>
    <p class="xieyiTit">- 反对宪法所确定的基本原则的；</p>
    <p class="xieyiTit">
      - 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统⼀的；
    </p>
    <p class="xieyiTit">- 损害国家荣誉和利益的；</p>
    <p class="xieyiTit">- 煽动⺠族仇恨、⺠族歧视，破坏⺠族团结的；</p>
    <p class="xieyiTit">- 破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
    <p class="xieyiTit">- 散布谣⾔，扰乱社会秩序，破坏社会稳定的；</p>
    <p class="xieyiTit">
      - 散布淫秽、⾊情、赌博、暴⼒、凶杀、恐怖或者教唆犯罪的；
    </p>
    <p class="xieyiTit">- 侮辱或者诽谤他⼈，侵害他⼈合法权益的；</p>
    <p class="xieyiTit">- 含有法律、⾏政法规禁⽌的其他内容的。</p>
    <p class="xieyiTit">
      您承诺发表⾔论要：爱国、守法、⾃律、真实、⽂明。不传播任何⾮法的、骚扰性的、中伤他⼈的、
      辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、危害国家安全的、泄露国家机密的、破坏国家宗
      教政策和⺠族团结的以及其它违反法律法规及政策的内容。若您的⾏为不符合以上提到的服务协议你拖我拉
      将作出独⽴判断⽴即暂停或终⽌您的服务账号。您需⾃⾏对其全部⾏为承担法律责任，你拖我拉不承担
      任何法律责任。
    </p>
    <p class="xieyiTit">
      3.3
      您不得发布、传送、传播、储存侵害他⼈名誉权、肖像权、知识产权、商业秘密等合法权利的内
      容。
    </p>
    <p class="xieyiTit">
      3.4 您不得制作、复制、发布、传播涉及他⼈隐私、个⼈信息或资料的信息。
    </p>
    <p class="xieyiTit">
      3.5
      您不得发表、传送、传播骚扰、⼴告信息、过度营销信息及垃圾信息或含有任何性或性暗⽰的内
      容。
    </p>
    <p class="xieyiTit">
      3.6
      您应当对您使⽤你拖我拉的⼀切⾏为及所有在您账⼾下的活动（不论是您还是您授权他⼈的活动）负
      责。您不得利⽤你拖我拉从事违反法律法规、违反本⽤⼾协议约定、危害⽹络安全、危害计算机系统安
      全、破坏你拖我拉产品完整性或其他侵害你拖我拉及任何第三⼈利益的活动。若您从事了上述⾏为，你拖我拉
      有权暂停或终⽌您的账⼾，并拒绝您于现在和未来使⽤你拖我拉之全部或部分功能，由此对你拖我拉或第
      三⼈造成的损失或由此产⽣的⼀切法律责任均由您全部承担。
    </p>
    <span class="xiaoti">4. 内容和知识产权</span>
    <p class="xieyiTit">
      4.1
      您在你拖我拉提交的内容之所有权归您所有。您明⽩你拖我拉对您提供的任何内容不在任何⽅⾯负
      责，你拖我拉没有义务预先审查此类内容。
    </p>
    <p class="xieyiTit">
      若您使⽤本服务的⾏为存在或涉嫌任何违反国家法律法规或侵犯任何第三⽅合法权
      益的情形，你拖我拉有权直接删除该等违反规定的信息，并可以暂停或终⽌向您服务。
    </p>
    <p class="xieyiTit">
      4.2
      你拖我拉的所有权及所有与你拖我拉服务相关的知识产权（包括但不限于源代码、⽬标代码、⽂档资料、
      界⾯设计、版⾯框架、以及任何由你拖我拉根据维护与技术⽀持提供的软件、资料等）归你拖我拉所
      有。您认同你拖我拉拥有的所有权及知识产权，并承诺尊重及不以任何⽅式侵害你拖我拉
      的所有权及知识产权。未经你拖我拉书⾯许可，您不能复制、拷⻉、转让、许可或提供他⼈使⽤上述
      知识成果，或者私⾃使⽤任何部分代码和外观设计，否则您应承担相应的法律责任，且你拖我拉有权
      终⽌服务且不退还任何费⽤。
    </p>
    <p class="xieyiTit">
      4.3
      对于企业⽤⼾，我们可能在我们的⽹站、我们举办的活动或其他宣传推⼴中使⽤企业⽤⼾的名
      称、字号、商标等信息或标识以对本服务进⾏宣传。
    </p>
    <span class="xiaoti">5. 隐私政策</span>
    <p class="xieyiTit">
      5.1
      您在使⽤你拖我拉前，通过阅读和点击同意《你拖我拉隐私政策》并实际使⽤本服务，即表⽰您授权你拖我拉合法收集和使⽤有关您及您所使⽤本服务的技术性或诊断性信息。基于提供服务所必需，⼿机端
      ⽤⼾仅在⽤于反馈建议和问题时调⽤录⾳、拍照、读取相册等权限。收集到的这些信息将⽤于
      改进你拖我拉服务的内容和技术，提升我们的服务品质。你拖我拉不会将您的信息和内容分享或出售给第三⽅。
    </p>
    <p class="xieyiTit">但以下情况除外：</p>
    <p class="xieyiTit">- 基于特定的服务⽬的且事先获得您的明确授权；</p>
    <p class="xieyiTit">- 遵守适⽤的法律法规；</p>
    <p class="xieyiTit">- 遵守法院命令或其他法律程序的规定；</p>
    <p class="xieyiTit">- 遵守相关政府主管部⻔的要求；</p>
    <p class="xieyiTit">
      - 与国家安全、国防安全、公共安全、公共卫⽣、公共利益直接相关的情形下；
    </p>
    <p class="xieyiTit">
      -
      从合法公开披露的信息中收集个⼈信息的，如合法的新闻报道、政府信息公开等渠道；
    </p>
    <p class="xieyiTit">
      -
      你拖我拉认为为遵守适⽤的法律法规、维护社会公共利益、或保护你拖我拉的客⼾、其他⽤⼾或雇
      员的⼈⾝和财产安全或合法权益所合理必需的情形下；
    </p>
    <p class="xieyiTit">
      - 您违反了本服务协议，且你拖我拉出于维权需要向第三⽅提供的情形下；
    </p>
    <p class="xieyiTit">- 法律法规规定的其他情形。</p>
    <p class="xieyiTit">
      5.2 你拖我拉将按照《你拖我拉隐私政策》的约定收集、
      使⽤、储存和分享您的个⼈信息，以及您如何管理您的个⼈信息、我们如何采取必要措施保障您的个
      ⼈信息安全、我们响应您的权利请求等。您声明并保证，您已仔细阅读、完全理解并同意上述《你拖我拉
      隐私政策》的全部内容。若您不同意本协议及《你拖我拉隐私政策》，请您⽴即停⽌使⽤我们提供的任何
      服务或产品。
    </p>
    <span class="xiaoti">6. 数据和信息安全</span>
    <p class="xieyiTit">
      6.1
      我们与您共同负有维护账号安全的责任。我们在⽬前的技术⽔平下努⼒保护您的账号在服务器端
      的安全，并不断更新技术措施。但互联⽹并⾮绝对安全的环境，我们特别提醒您应该妥善保管您的账
      号和密码，杜绝私⾃转借乃⾄出售账号等有害于账号安全的⾏为。此外，你拖我拉建议您采⽤复杂字
      符组合设定您的账号密码。请您理解，你拖我拉不对因您⾃⾝有违相关法律法规规定和本协议约定使
      ⽤账号的⾏为及其所导致的数据未经授权访问、泄露、破坏乃⾄销毁等造成的损失承担责任。
    </p>
    <p class="xieyiTit">
      6.2
      你拖我拉将依照法律、⾏政法规的规定和国家标准的强制性要求，采取合理的技术措施和其他必
      要措施，努⼒保护您的数据和信息安全。但是，互联⽹数据传输、存储、转换均可能存在⼀定未知且
      不确定的数据安全⻛险，该等⻛险将导致包括但不限于数据丢失、泄露、损坏、⽆法读取或提取等后
      果。您确认，您已明确知晓并同意接受该等因互联⽹引发的⻛险和后果，并已采取恰当的措施（例如
      数据备份等），以便在该等⻛险发⽣时将损失降⾄最低。
    </p>
    <p class="xieyiTit">
      6.3
      您知悉并认可，你拖我拉服务是基于第三⽅云服务所提供的SAAS服务，鉴于⽹络服务的特殊性（包
      括但不限于云服务器的稳定性问题、通讯基础设施故障、⽹络⿊客攻击、政府管制等⾏为的存在及其
      他我们⽆法控制的情形），我们提供的服务可能会出现中断、不能及时传送信息或其他不能满⾜您的
      要求的情况，请您知悉并理解，该情况将不视为我们违反本协议约定，但我们将尽可能及时通过⽹⻚
      公告、系统通知、短信或者其他合理的⽅式通知您。
    </p>
    <p class="xieyiTit">
      您可⾃⾏对您上传、储存在你拖我拉中的数据进⾏删除、更改等操作。就数据的删除、更改等操作，您应
      谨慎操作。当服务提前终⽌（包括账⼾注销或其他原因导致的提前终⽌等）时，除法律法规明确规
      定、主管部⻔要求或双⽅另有约定外，你拖我拉仅在⼀定的缓冲期（以您所订购的服务适⽤的专有协议、
      产品⽂档、服务说明等所载明的时限为准）内继续存储您的数据（如有），缓冲期届满后，你拖我拉
      将删除所有的⽤⼾业务数据，包括所有缓存或者备份的副本，不再保留您的任何数据。⽤⼾业务数据
      ⼀经删除，即不可恢复；您应承担数据因此被删除所引发的后果和责任，您理解并同意，在服务终⽌
      后与您约定的缓冲期届⾄时，你拖我拉没有继续保留、导出或者返还⽤⼾业务数据的义务。
    </p>
    <span class="xiaoti">7. 免责声明</span>
    <p class="xieyiTit">
      您知悉并同意，下列情况下，你拖我拉⽆需向您承担任何责任：
    </p>
    <p class="xieyiTit">
      7.1
      由于您维护不当或保密不当，导致数据、资料、⼝令、密码等丢失或泄漏，或其他⾮因你拖我拉
      原因导致的个⼈信息的泄漏，你拖我拉不承担任何法律责任；
    </p>
    <p class="xieyiTit">
      7.2
      任何由于不可抗⼒、⿊客攻击、电脑病毒侵⼊、⽹络运营商原因或政府管制等你拖我拉⽆法合理
      控制的原因⽽造成你拖我拉使⽤中⽌、中断、延误、使⽤限制、使⽤异常、使⽤不能、暂时性⽹站关闭
      等，你拖我拉不承担任何法律责任；
    </p>
    <p class="xieyiTit">
      7.3
      您应对通过你拖我拉上传或储存⽂件、输⼊数据的合法性以及获得⽂件、数据、内容的⽅式的合法性
      负责，因上述⽂件、数据、内容引发的⼀切纠纷，由您承担全部法律责任，你拖我拉不承担任何法律
      责任；
    </p>
    <p class="xieyiTit">
      7.4
      互联⽹是⼀个开放平台，如果您将图⽚、⽂字等内容上传并传播，有可能会被其他组织或个⼈复
      制、转载、擅改或做其它⾮法⽤途，您应充分意识到此类⻛险的存在。您同意使⽤你拖我拉服务所存
      在的此类⻛险及后果将完全由您⾃⼰承担，你拖我拉不承担任何责任。
    </p>
    <p class="xieyiTit">
      7.5
      您理解并确认，受技术所限，你拖我拉⽆法保证其所提供的服务毫⽆瑕疵，但我们承诺将不断提
      升服务质量及服务⽔平，为您提供更加优质的服务。据此，您同意：如果你拖我拉所提供的服务虽然
      存在瑕疵，但该等瑕疵是当时⾏业技术⽔平所⽆法避免的，其将不被视为违约，你拖我拉⽆须因此向
      您承担任何责任。
    </p>
    <p class="xieyiTit">
      7.6
      我们通过中华⼈⺠共和国境内的设施提供服务，我们⽆法确保您的使⽤⾏为、发布的信息及内容
      在其他国家或地区是适当的、可⾏的，当您在其他司法辖区使⽤本软件及相关服务时，您应⾃⾏确保
      其遵守当地的法律法规，我们对此不承担任何责任。
    </p>
    <p class="xieyiTit">
      7.7
      如我们发现、或通过第三⽅举报或投诉获知，您发布的内容存在或涉嫌违反国家法律法规或本软
      件规则的，我们有权依据合理判断不经通知⽴即采取⼀切必要措施（包括但不限于⽴刻删除您所发布
      的相关内容、冻结账⼾封号、向公安机关报案等）或配合国家⽹信部⻔、公检法等国家机关的执法要
      求对帐⼾做相应处理以减轻或消除您⾏为所造成的影响。
    </p>
    <span class="xiaoti">8. 服务变更/中断/终⽌</span>
    <p class="xieyiTit">
      8.1
      您可随时注销您的账⼾并停⽌使⽤你拖我拉服务。注销账⼾的⽅式可遵照你拖我拉官⽹的指引。您声明并
      保证，在您⾃主注销账⼾前已经将您上传、储存在你拖我拉中的数据下载、备份完毕。您⾃主注销账⼾并
      经过缓冲期后，你拖我拉将不再负有保留您数据的任何法定或者约定义务。
    </p>
    <p class="xieyiTit">
      8.2
      您理解并同意，如因系统维护或升级的需要⽽暂停你拖我拉服务、调整服务功能的，你拖我拉将尽可
      能事先在⽹站上进⾏通告，以便于您提前做好商业安排，努⼒将对您带来的实际影响降到最低，但不
      因此承担任何法律责任。
    </p>
    <p class="xieyiTit">
      8.3
      当出现如下任⼀情形时，你拖我拉可径⾏终⽌或暂停您使⽤所有或部分账⼾和/或本服务：
    </p>
    <p class="xieyiTit">
      - 您提供的资料不真实，或违反其他⽤⼾个⼈资料提供规则；
    </p>
    <p class="xieyiTit">- 您违反法律法规、本协议或您与你拖我拉的其他约定；</p>
    <p class="xieyiTit">- 您要求取消或终⽌您的账⼾；</p>
    <p class="xieyiTit">
      - 未经你拖我拉同意，将你拖我拉产品⽤于商业⽬的或者其他任何营利性⽤途；
    </p>
    <p class="xieyiTit">
      - 您对你拖我拉的访问或使⽤可能会对你拖我拉服务造成安全⻛险；
    </p>
    <p class="xieyiTit">- 需要听从法庭传票、法律命令或遵循法律程序；</p>
    <p class="xieyiTit">- 根据相关法律法规或政府机构的要求；</p>
    <p class="xieyiTit">- 因⾃然灾害、战争等不可抗⼒；</p>
    <p class="xieyiTit">
      - 本协议发⽣变更或修改，您不同意变更或修改后的⽤⼾协议。
    </p>
    <p class="xieyiTit">
      8.4
      您理解并认可，为技术升级、服务体系升级、或因经营策略调整或配合国家重⼤技术、法规政策
      等变化，你拖我拉不保证永久地提供某种服务，或可能变更所提供服务的形式、规格或其他⽅⾯，
      在终⽌该种服务或进⾏此种变更前，你拖我拉将尽最⼤努⼒且提前以⽹站公告、站内信、邮件或短信
      等⼀种或多种⽅式进⾏事先通知；如终⽌提供该种服务的，你拖我拉将尽合理的努⼒给您预留合理的
      时间，妥善处理后续事宜。
    </p>
    <p class="xieyiTit">
      8.5
      ⼀旦您的账⼾被终⽌，您将⽆法正常使⽤本服务。此外，您可以选择在账⼾终⽌前删除您的账⼾
      下的所有信息和内容。
    </p>
    <span class="xiaoti">9. 本服务协议的完善和修改</span>
    <p class="xieyiTit">
      9.1 你拖我拉有权根据互联⽹的发展和中华⼈⺠共和国有关法律、法规的
      变化，不时地完善和修改本协议。你拖我拉保留随时修改本协议的权利，并会将修改后的服务协议在
      你拖我拉官⽹上公布，您有义务及时查看官⽹上的消息更新，该等公布⾏为即可被合理地视为对您进⾏了
      有效通知送达。您在使⽤你拖我拉的服务时，有必要对最新的《你拖我拉服务协议》进⾏仔细阅读和重新
      确认，经您确认和同意后，当发⽣相关争议时，以最新的服务协议为准。
    </p>
    <p class="xieyiTit">
      9.2 如果您不同意你拖我拉对本协议所做的修改，您应⽴即停⽌使⽤你拖我拉服务。
      如果⽤⼾继续使⽤你拖我拉服务，则视为⽤⼾接受我⽅对本协议所做的修改。
    </p>
    <span class="xiaoti">10. ⻘少年特别协议</span>
    <p class="xieyiTit">
      10.1
      你拖我拉服务主要⾯向成年⼈。若您是未成年⼈，请您确保您的监护⼈已仔细阅读、理解并接受本
      协议。并在征得您的监护⼈同意的前提下使⽤你拖我拉服务提供信息。
    </p>
    <p class="xieyiTit">
      10.2
      如您的监护⼈不同意您按照本协议使⽤你拖我拉服务或向我们提供信息，请您⽴即终⽌使⽤我们的
      服务并及时通知我们，以便我们采取相应的措施。
    </p>
    <p class="xieyiTit">
      10.3 您声明并保证，您是具有完全⺠事⾏为能⼒的⾃然⼈。若您违背上述保证购买
      服务的，你拖我拉不因此承担任何包括但不限于返还价款在内的任何法律责任，由此造成的损失应由
      您或您的监护⼈⾃⾏承担。
    </p>
    <span class="xiaoti">11. 法律适⽤及争议解决</span>
    <p class="xieyiTit">
      11.1
      本协议之订⽴、⽣效、解释、修订、补充、终⽌、执⾏与争议解决均适⽤中华⼈⺠共和国⼤陆
      法律；如法律⽆相关规定的，参照商业惯例或者⾏业惯例。
    </p>
    <p class="xieyiTit">
      11.2
      您因使⽤你拖我拉服务所产⽣及与本协议有关的⼀切争议，由你拖我拉与您协商解决。协商不成
      时，任何⼀⽅均可向你拖我拉住所地⼈⺠法院提起诉讼。
    </p>
    <p class="xieyiTit">
      11.3
      若⽤⼾利⽤本服务从事任何违法或侵权⾏为，由⽤⼾⾃⾏承担全部责任，你拖我拉不承担任何
      法律责任。因此给你拖我拉或任何第三⽅造成任何损失的，⽤⼾应负责全额赔偿。
    </p>
    <span class="xiaoti">12. 与我们联系</span>
    <p class="xieyiTit">
      当您有其他的投诉、建议或反馈时，请与我们联系。您可以将您的问题发送⾄service@nituowola.com
    </p>
    <span class="xiaoti">13. 其他协议</span>
    <p class="xieyiTit">
      13.1
      当涉及本协议的全部或者部分权利义务转移给你拖我拉的关联公司时，你拖我拉将尽合理商业
      努⼒（包括但不限于电⼦邮件、短信或者官⽹公告），与您取得联系并就相关事宜进⾏协商，与此同
      时，你拖我拉保证不会实质性降低您可获得服务的质量。
    </p>
    <p class="xieyiTit">
      13.2 未经你拖我拉事先同意，您不得将本协议项下的任何权利义务转让给第三⼈。
    </p>
    <p class="xieyiTit">
      13.3
      本协议部分协议因任何原因被宣告⽆效或不可履⾏的，不当然地影响其他协议的效⼒，其他协
      议仍应继续适⽤。任何⽆效或不可履⾏的协议将被替换为与本协议⽬的和意图⼀致的另⼀协议。
    </p>
    <p class="xieyiTit">
      13.4
      因超出你拖我拉合理控制的情况致使你拖我拉未能遵守所有协议和条件，则你拖我拉对这类不
      履约概不承担任何责任。
    </p>
    <p class="xieyiTit">
      13.5
      你拖我拉可通过站内消息或您在注册账⼾、使⽤你拖我拉服务过程中提供的电话或其他
      联系⽅式向您发出通知。上述通知⼀经发出即视为对您产⽣效⼒，您有义务保证您的联系⽅式的有
      效性。
    </p>
    <p class="xieyiTit">
      13.6
      除⾮你拖我拉以书⾯⽅式确认并同意，你拖我拉未履⾏本协议中任何权利或协议的⾏为不构成
      你拖我拉对相关权利或协议的放弃。
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.box {
  background-color: #f8fafc;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0 10px;
}

.xieyi {
  font-size: 16px;
  margin-top: 5px;
  text-align: center;
}

.xieyiTit {
  text-indent: 2em;
  font-size: 14px;
  margin-top: 3px;
  line-height: 25px;
}

.biaoti {
  font-weight: bold;
  margin-top: 5px;
}

.xiaoti {
  margin-top: 5px;
}
</style>